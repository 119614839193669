import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const isSupportedBrowser = browser.satisfies({
  windows: {
    'internet explorer': '>10',
    edge: '>16',
  },
  macos: {
    safari: '>10.1',
  },
  firefox: '>=52',
  chrome: '>=49',
});

let supported = true;
if (isSupportedBrowser === false) {
  supported = false;
}

export default supported;
