import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/url';
import 'core-js/features/string/includes';
import 'core-js/features/string/pad-start';
import 'events-polyfill/src/constructors/Event';

// IE11 needs a polyfill for AbortController.
// See: https://github.com/mo/abortcontroller-polyfill#using-it-on-internet-explorer-11-msie11
import 'abortcontroller-polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './styles/index.css';

import isSupportedBrowser from './lib/browser-detect';
import LoadingScreen from 'Components/LoadingScreen';

const UnsupportedBrowser = React.lazy(() => import('./Pages/UnsupportedBrowser'));
const Root = React.lazy(() => import('./root'));

ReactDOM.render(
  <React.Suspense fallback={<LoadingScreen text="Loading..." />}>
    {!isSupportedBrowser ? <UnsupportedBrowser /> : <Root />}
  </React.Suspense>,
  document.getElementById('app-container'),
);
