import { Flex, Box, Logomark, Text } from '@sajari-ui/core';

interface Props {
  text?: string;
}

const LoadingScreen = ({ text, ...rest }: Props) => (
  <Flex
    textAlign="text-center"
    width="w-screen"
    height="h-screen"
    alignItems="items-center"
    justifyContent="justify-center"
    backgroundColor="bg-gray-900"
    {...rest} // rest is useful for setting data-testid
  >
    <Box>
      <Logomark animated display="inline-flex" />
      <Text textColor="text-white" fontWeight="font-normal" fontSize="text-xl" margin="mt-4">
        {text}
      </Text>
    </Box>
  </Flex>
);

export default LoadingScreen;
